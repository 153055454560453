import { call, put, select, takeLatest, take, all } from "redux-saga/effects";
import { logError } from "../../infrastructure/logging/Logger";
import {
  actionTypes,
  configRequested,
  getFormConfigFailed,
  getFormConfigSuccess,
  updateFormConfigFailed,
  updateFormConfigSuccess,
  updateFormDataFailed,
  updateFormDataSuccess,
  updateFormErrorFailed,
  updateFormErrorSuccess,
  updateLeadEditError,
  setStepWiseMandatoryFields
} from "./Actions";
import {
  actionTypes as appActionTypes,
  masterDataInit,
  masterDataCompleted,
  masterDataFail,
  userDetailInit,
  userDetailFailed,
  userDetailSuccess,
  userDetailUpdateSuccess
} from "../../shared/services/appData/Actions";
import { Service } from "./Services";
import { checkIfArray, getFieldConfig, getMasterDataByKey, getStepWiseMandatoryFields, updateFormDataFromUserInfo } from "../../shared/utilties/Utils";
import { cloneDeep, find, forEach, get, intersection, isNil, pickBy, set } from "lodash";
import { OfflineStorage } from "../../shared/utilties/OfflineStorage";
import { FORM_INPUT_UI, STORAGE_KEY, STORAGE_TYPE } from "../../shared/utilties/Constants";

export const getFormConfigData = state => state.formReducer.data;
export const getFormConfigURL = state => state.formReducer.formConfigURL;
export const getAllMasterData = state => state.appDataReducer.masterData;
export const getUserDetailData = state => state.appDataReducer.userDetail;
export const getClientInfoData = state => state.appDataReducer.clientInfo;
export const getFormData = state => state.formReducer.formData;
export const getAppDataReducer = state => state.appDataReducer;
export const getFormReducer = state => state.formReducer;
export function* getConfig(action) {
  //CAll api
  try {
    const masterData = yield select(getAllMasterData); // <-- get reducer data
    const userDetail = yield select(getUserDetailData); // <-- get reducer data
    const clientInfo = yield select(getClientInfoData); // <-- get reducer data 
    const getFormReducer = state => state.formReducer;
    const formData = yield select(getFormData);
    if (
      masterData === null ||
      masterData === undefined ||
      Object.keys(masterData).length === 0
    ) {
      yield put({
        type: appActionTypes.MASTERDATA_INIT,
        payload: {
          data: {
            nextAction: actionTypes.GET_FORM_CONFIG,
            payload: action.payload && action.payload
          }
        }
      });
      yield take([masterDataInit, masterDataFail, masterDataCompleted]);
    } else {
      yield put(configRequested(""));
      const formConfig = yield select(getFormConfigData); // <-- get reducer data
      const formConfigUrlReducer = yield select(getFormConfigURL)
      let stepWiseMandatoryFields=null;
      let finalFormConfigUrl = "";
      if (formConfig && Object.keys(formConfig).length > 0) {
       /*stepWiseMandatoryFields= getStepWiseMandatoryFields(formConfig,clientInfo,masterData,formData);
       if(!!stepWiseMandatoryFields)
        yield put(setStepWiseMandatoryFields(stepWiseMandatoryFields));*/
        yield put(getFormConfigSuccess(formConfig, formConfigUrlReducer));
      } else {
        let result = null;
        const rootFolderURL = get(window, "extraaedge.client.pageConfiguration.form_root")
        ? `${window.extraaedge.API_END_POINT.getContent}${window.extraaedge.client.pageConfiguration.form_root}`
        : ""    
        if(!!rootFolderURL && action.payload.data!==rootFolderURL){
          const resultAll = yield all([call(Service.getConfig,action.payload.data),call(Service.getConfig, rootFolderURL)]);      
          if(resultAll && Array.isArray(resultAll) && resultAll.length > 0){
            if(resultAll[0]!==null){
              finalFormConfigUrl = action.payload.data;
              result=resultAll[0];
            }else if(resultAll[1]!==null){
              result=JSON.parse(JSON.stringify(resultAll[1]));
              finalFormConfigUrl = rootFolderURL;
            }
          }
        } else if(action.payload && !!action.payload.data){
          result = yield call(Service.getConfig, action.payload.data);
        }
        
       
        /***** */
        const formData = yield call(updateFormDataFromUserInfo, result);
        yield put({
          type: actionTypes.UPDATE_FORM_DATA_SUCCESS,
          payload: {
            data: formData
          }
        });
        yield put({
          type: actionTypes.UPDATE_FORM_INITIAL_DATA,
          payload: {
            data: Object.assign({}, formData)
          }
        });
        /*** */
       stepWiseMandatoryFields= getStepWiseMandatoryFields(result,clientInfo,masterData,formData);
       if(!!stepWiseMandatoryFields)
        yield put(setStepWiseMandatoryFields(stepWiseMandatoryFields));
        yield put(getFormConfigSuccess(result, finalFormConfigUrl));
      }
    }
  } catch (error) {
    logError(error);
    yield put(getFormConfigFailed(JSON.stringify(error)));
  }
}

export function* updateFormData(action) {
  try {
    yield put(updateFormDataSuccess(action.payload.data));
    const fieldKeys = Object.keys(action.payload.data);
    const formReducer = yield select(getFormReducer);
    const masterData = yield select(getAllMasterData); // <-- get reducer data
    const userDetail = yield select(getUserDetailData); // <-- get reducer data
    let updatedUserDetail = null;
    try {
      updatedUserDetail = JSON.parse(JSON.stringify(userDetail));
    } catch (error) {
      updatedUserDetail = null;
    }
    try {
      if(updatedUserDetail && fieldKeys && fieldKeys.length>0 && !!formReducer?.data?.conditionalSetValue && Object.keys(formReducer.data.conditionalSetValue).length>0){
        const activeConditionalSetValueObject = pickBy(formReducer.data.conditionalSetValue,{isActive:true});
        let isUserDetailUpdated=false;
        let isUpdateFormData = false;
        let updatedFormData = cloneDeep(action.payload.data);
        if(activeConditionalSetValueObject && Object.keys(activeConditionalSetValueObject).length>0){
          const fieldsKeyFoundToSetConditionalValue = intersection(Object.keys(activeConditionalSetValueObject),fieldKeys);
          if(fieldsKeyFoundToSetConditionalValue.length>0){
            fieldsKeyFoundToSetConditionalValue.forEach(key => {
              const item = activeConditionalSetValueObject[key];
              if(!item?.conditionalValueTemplate && !!item?.checkValueBy && !!item?.setValueTo?.key && !!item?.setValueTo?.dataType && ["number","text"].includes(item.setValueTo.dataType)){
                if(!isNil(get(action.payload.data,`${key}`)) && Array.isArray(get(action.payload.data,`${key}`))){
                  console.info("conditionalSetValue only allowed for number and text type value");
                }else{
                  const valueToCheck = isNil(get(action.payload.data,`${key}`)) || Array.isArray(get(action.payload.data,`${key}`)) ? null : parseFloat(get(action.payload.data,`${key}`));
                  switch(item.checkValueBy){
                    case "range":{
                      if(!!item.ranges && Array.isArray(item.ranges) && item.ranges.length>0){
                        const rangeFound =!isNil(valueToCheck)? find(item.ranges,i=>{
                          if(!isNil(i?.greaterThan) && !isNil(i?.lessThanOrEqual) && !isNil(i?.setValue))
                          return i.greaterThan<valueToCheck && i.lessThanOrEqual>=valueToCheck;
                        }):null;
                        if(!isNil(rangeFound)){
                          const newValue= item.setValueTo.dataType==="text"?rangeFound.setValue.toString():rangeFound.setValue;
                          set(updatedUserDetail,`${item.setValueTo.key}`,newValue);
                          isUserDetailUpdated=true;
                        }else{
                          set(updatedUserDetail,`${item.setValueTo.key}`,null);
                          isUserDetailUpdated=true;
                        }
                      }
                    }break;
                    case "includes":{
                      if(!!item.includes && Array.isArray(item.includes) && item.includes.length>0){
                        const conditionFound = !isNil(valueToCheck)? find(item.includes,j=>{
                          if(!isNil(j?.expectedValues) && Array.isArray(j.expectedValues) && j.expectedValues.length>0 && !isNil(j?.setValue))
                          return j.expectedValues.includes(valueToCheck);
                        }):null;
                        if(!isNil(conditionFound)){
                          const newValue= item.setValueTo.dataType==="text"?conditionFound.setValue.toString():conditionFound.setValue;
                          set(updatedUserDetail,`${item.setValueTo.key}`,newValue);
                          isUserDetailUpdated=true;
                        }else{
                          set(updatedUserDetail,`${item.setValueTo.key}`,null);
                          isUserDetailUpdated=true;
                        }
                      }
                    }break;
                    case "equal":{
                      if(!!item.equal && Array.isArray(item.equal) && item.equal.length>0){
                        const equalConditionFound = !isNil(valueToCheck)? find(item.equal,j=>{
                          if(!isNil(j?.compareWith) && !isNil(j?.setValue))
                          return j.compareWith.toString()===valueToCheck.toString();
                        }):null;
                        if(!isNil(equalConditionFound)){
                          const newValue= item.setValueTo.dataType==="text"?equalConditionFound.setValue.toString():equalConditionFound.setValue;
                          set(updatedUserDetail,`${item.setValueTo.key}`,newValue);
                          isUserDetailUpdated=true;
                        }else{
                          set(updatedUserDetail,`${item.setValueTo.key}`,null);
                          isUserDetailUpdated=true;
                        }
                      }
                    }break;
                    default:{
    
                    }
                  }
                }
              }
            });
          }
          const { data, formData } = formReducer || {};
          Object.keys(activeConditionalSetValueObject).forEach((key) => {
            const { conditionalValueTemplate: conditionalValueTemplateName, editable, defaultValue, setValueTo: { key: setValueToKey , dataType} = {}} = activeConditionalSetValueObject[key] || {};
            let conditionalValueTemplate = conditionalValueTemplateName || "";
            const isUpdateFieldValue = editable ? true : !(get(updatedUserDetail,setValueToKey) || "");
            if(!!conditionalValueTemplate && isUpdateFieldValue){
              let fieldIds = [];
              try{
                fieldIds = conditionalValueTemplate.match(/\$(.*?)\$/g)?.map(match => match.slice(1, -1)) || [];
              }catch(error){
                console.log("Unable to match",error);                
              }
              let isFieldValuePresent = false;
              if(checkIfArray(fieldIds)){
                fieldIds.forEach(fieldId => {
                  const { type, dataCollectionName, dataCollectionMap: { value : dataCollectionMapValue, label} = {} } = getFieldConfig(data, fieldId) || {};
                  const fieldValue = formData[fieldId];
                  if(fieldValue){
                    isFieldValuePresent = true;
                    switch(type){
                      case FORM_INPUT_UI.SELECT:
                        const masterFieldCollection = getMasterDataByKey(dataCollectionName, masterData);
                        if(checkIfArray(masterFieldCollection) && fieldValue){
                          const selectedMasterField = find(masterFieldCollection, i => (checkIfArray(fieldValue) ? fieldValue.includes(i[dataCollectionMapValue] || i.id) : fieldValue === (i[dataCollectionMapValue] || i.id))) || {};
                          conditionalValueTemplate = conditionalValueTemplate.replaceAll(`$${fieldId}$`, (get(selectedMasterField,`${label || "name"}`) || ""));
                        }
                        break;
                      case FORM_INPUT_UI.TEXT:
                        conditionalValueTemplate = conditionalValueTemplate.replaceAll(`$${fieldId}$`, (fieldValue || ""));
                        break;
                    }
                  }else{
                    conditionalValueTemplate = conditionalValueTemplate.replaceAll(`$${fieldId}$`, "");
                  }                  
                })
              }              
              if(!isFieldValuePresent && defaultValue){
                isUserDetailUpdated = true;
                conditionalValueTemplate = defaultValue;
                set(updatedUserDetail,`${setValueToKey}`,defaultValue);
              }else if(isFieldValuePresent){
                const separators = [...new Set(conditionalValueTemplate.replace(/[a-zA-Z0-9\s]/g, "").split(""))];
                checkIfArray(separators) && separators.forEach((sep) => {
                  const pattern = new RegExp(`\\${sep}{2,}`, "g"); 
                  conditionalValueTemplate = conditionalValueTemplate.replace(pattern, sep); 
                  conditionalValueTemplate = conditionalValueTemplate.replace(new RegExp(`^\\${sep}|\\${sep}$`, "g"), "");
                });
                isUserDetailUpdated = true;
                const { dataCollectionName } = getFieldConfig(data, key);
                if(dataCollectionName){
                  const valueToSet =  find(getMasterDataByKey(dataCollectionName, masterData), i => i.name === conditionalValueTemplate) || [];
                  conditionalValueTemplate = valueToSet?.id || defaultValue;
                } 
                set(updatedUserDetail,`${setValueToKey}`,conditionalValueTemplate);
              }
              if(isUserDetailUpdated){
                isUpdateFormData = true;
                updatedFormData = {
                  ...updatedFormData,
                  [key]: conditionalValueTemplate
                };
              }
                
            }
          }) 
        }
        if(isUserDetailUpdated && updatedUserDetail){
          yield put(userDetailUpdateSuccess(updatedUserDetail))
        }
        if(isUpdateFormData){
          yield put(updateFormDataSuccess(updatedFormData));
        }
      }
    } catch (error) {
      
    }
  } catch (error) {
    logError(error);
    yield put(updateFormDataFailed(JSON.stringify(error)));
  }
}
export function* updateFormConfig(action) {
  //CAll api
  try {
    yield put(updateFormConfigSuccess(action.payload.data));
  } catch (error) {
    logError(error);
    yield put(updateFormConfigFailed(JSON.stringify(error)));
  }
}

export function* updateFormError(action) {
  //CAll api
  try {
    yield put(updateFormErrorSuccess(action.payload.data));
  } catch (error) {
    logError(error);
    yield put(updateFormErrorFailed(JSON.stringify(error)));
  }
}
export function* updateLeadEditErrorSaga(action) {
  //CAll api
  try {
    yield put(updateLeadEditError(action.payload.leadEditError));
  } catch (error) {
    logError(error);
  }
}

export function* watchConfigData() {
  yield takeLatest(actionTypes.GET_FORM_CONFIG, getConfig);
}
export function* watchUpdateFormData() {
  yield takeLatest(actionTypes.UPDATE_FORM_DATA, updateFormData);
}
export function* watchUpdateFormConfig() {
  yield takeLatest(actionTypes.UPDATE_FORM_CONFIG, updateFormConfig);
}
export function* watchUpdateFormError() {
  yield takeLatest(actionTypes.UPDATE_FORM_ERROR, updateFormError);
}
export function* watchUpdateLeadEditError() {
  yield takeLatest(actionTypes.LEAD_EDIT_ERROR, updateLeadEditErrorSaga);
}


export function* updateFormDataAccordingToInst(formConfig, clientInfo) {
  // const {formConfig, clientInfo} = get(action, "payload.data") || {};
  let stepWiseMandatoryFields = null;
  const masterData = yield select(getAllMasterData); // <-- get reducer data
  const isRequiredToResetFormData = JSON.parse(
    OfflineStorage.getItem(STORAGE_KEY.RESET_FORM_DATA, STORAGE_TYPE.LOCAL) ||
      "false"
  );
  if (isRequiredToResetFormData) {
    const formData = yield call(updateFormDataFromUserInfo, formConfig);
    yield put({
      type: actionTypes.UPDATE_FORM_DATA_SUCCESS,
      payload: {
        data: formData,
      },
    });
    yield put({
      type: actionTypes.UPDATE_FORM_INITIAL_DATA,
      payload: {
        data: Object.assign({}, formData),
      },
    });
    stepWiseMandatoryFields = getStepWiseMandatoryFields(
      formConfig,
      clientInfo,
      masterData,
      formData
    );
    if (!!stepWiseMandatoryFields) {
      yield put(setStepWiseMandatoryFields(stepWiseMandatoryFields));
    }
    OfflineStorage.deleteItem(STORAGE_KEY.RESET_FORM_DATA, STORAGE_TYPE.LOCAL);
  }
}